import { notification } from 'antd';

const DEFAULT_AUTO_CLOSE_TIME = 4.5;

export const errorNotification = (message: string[], errorType?: string, autoCloseTime?: number | null) => {
  message.forEach((msg) => {
    notification.error({
      message: `${errorType}`,
      description: `${message}`,
      placement: 'topRight',
      duration: autoCloseTime ? autoCloseTime : DEFAULT_AUTO_CLOSE_TIME,
      className: 'yjErrorMsg',
    });
  });
};

export const successNotification = (message: string[], errorType?: string, autoCloseTime?: number | null) => {
  message.forEach((msg) => {
    notification.success({
      message: `${errorType}`,
      description: `${message}`,
      placement: 'topRight',
      duration: autoCloseTime ? autoCloseTime : DEFAULT_AUTO_CLOSE_TIME,
      className: 'yjSuccessMsg',
    });
  });
};

export const warningNotification = (message: string[], errorType?: string, autoCloseTime?: number | null) => {
  message.forEach((msg) => {
    notification.warning({
      message: `${errorType}`,
      description: `${message}`,
      placement: 'topRight',
      duration: autoCloseTime ? autoCloseTime : DEFAULT_AUTO_CLOSE_TIME,
      className: 'yjWarningMsg',
    });
  });
};

export const infoNotification = (message: string[], errorType?: string, autoCloseTime?: number | null) => {
  message.forEach((msg) => {
    notification.info({
      message: `${errorType}`,
      description: `${message}`,
      placement: 'topRight',
      duration: autoCloseTime ? autoCloseTime : DEFAULT_AUTO_CLOSE_TIME,
      className: 'yjInfoMsg',
    });
  });
};
