import { HomeFilled } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from '@app/hooks/useAppSelector';
import { selectAppState } from '@app/appSlice';
import RouteConfigType from '../../types/RouteConfigType';
import styles from './index.module.less';
import AppRoutes from '@app/routes';

const Breadcrumbs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs]: any[] = useState([]);

  const { dynamicBreadcrumbValues } = useAppSelector(selectAppState);

  const buildPath = (path: string): string => {
    const generatedPath = matchPath({ path }, location.pathname);
    return generatedPath ? generatedPath.pathname : path;
  };

  const _navigate = (path: string, e: any) => {
    e.preventDefault();
    navigate(path);
  };

  const pushToBreadcrumbs = (breadcrumbsArray: any[], route: any) => {
    if (route.useInBreadcrumbs) {
      breadcrumbsArray.push(route);
    }
  };

  useEffect(() => {
    const _breadcrumbArray: any = [];
    const buildBreadcrumbs = (routes: RouteConfigType[] | undefined) => {
      if (!routes) {
        return;
      }
      for (const route of routes) {
        if (
          matchPath(
            {
              path: route.path,
              end: route.exact,
            },
            location.pathname
          )
        ) {
          pushToBreadcrumbs(_breadcrumbArray, route);
          buildBreadcrumbs(route.routes);
        }
      }
    };
    buildBreadcrumbs(AppRoutes);
    setBreadcrumbs(_breadcrumbArray);
  }, [location, navigate]);

  const renderBreadcrumbValue = (i: RouteConfigType) => {
    return i.dynamicBreadcrumbIndex || i.dynamicBreadcrumbIndex === 0 ? dynamicBreadcrumbValues[i.dynamicBreadcrumbIndex] : i.title;
  };

  const _generateBreadcrumbs = () => {
    let keyCount = 0;
    return breadcrumbs.map((i: any) => {
      keyCount++;
      return i.disableBreadcrumb ? (
        <Breadcrumb.Item key={keyCount}>{i.title}</Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item href={buildPath(i.path)} onClick={(e) => _navigate(buildPath(i.path), e)} key={keyCount}>
          {renderBreadcrumbValue(i)}
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <div className={styles.yj_cp_breadcrumb_wrapper}>
      <Breadcrumb className={styles.yj_cp_breadcrumb}>
        <Breadcrumb.Item>
          <HomeFilled color={'#2F7F90'} />
        </Breadcrumb.Item>
        {_generateBreadcrumbs()}
      </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
