import constants from '@app/utils/Constants';
let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

const ChangeUrl = (path: any) => {
  const state = store.getState();
  const { endPoints, tenant } = state.app;
  path = path.url || path;
  let key = path.slice(0, path.indexOf('/api'));

  if (key) {
    let baseUrl = endPoints?.find((e: any) => e.serviceType.toString() == key);
    if (baseUrl) {
      let newBaseURL = baseUrl.entrypointAddress + '/' + tenant;
      let newUrl = path.slice(path.indexOf('/'));
      return [newBaseURL, newUrl];
    }
  }
  return [constants.baseUrlPlaceholder, path];
};

export default ChangeUrl;
