import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import RouteConfigType from '@app/types/RouteConfigType';
import { PrivateRoute } from '@app/utils/oktaAuthClient/PrivateRoute';
import LoginCallbackPage from '@pages/Login/LoginCallbackPage';
import LogoutPage from '@pages/Logout';
import PageNotFound from '@pages/PageNotFound';
import { Skeleton } from 'antd';
import MasterLayout from '@app/layouts/MasterLayout';

const RouteSwitch = (props: any) => {
  if (!props.routes) {
    return null;
  }
  return (
    <Routes>
      {props.routes.map((route: any) => (
        <Route key={route.path} path="/" element={<PrivateRoute />}>
          <Route key={route.path} path="/" element={<MasterLayout />}>
            <Route
              key={route.path}
              path={route.path}
              element={
                <Suspense fallback={<Skeleton />}>
                  <route.component />
                </Suspense>
              }
            />
          </Route>
        </Route>
      ))}
      <Route path="/login/callback" element={<LoginCallbackPage />} />
      <Route path="/logout" element={<LogoutPage />} />
      <Route path="/page-not-found" element={<PageNotFound />} />
      <Route path="*" element={<Navigate to="/page-not-found" />} />
    </Routes>
  );
};

export default RouteSwitch;
