import { FileAreaSettings } from '@app/types/fileAreaTypes';
import MenuItem from './types/MenuItemType';
import { WEBEndpointType } from '@iris/discovery.fe.client';
import { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import constants from './utils/Constants';
import { RootState } from './store/store';

export interface IAppState {
  endPoints: WEBEndpointType[] | null;
  tenant: string | null;
  user: null | UserClaims<CustomUserClaims>;
  selectedSite: string;
  publishedFileCount: number | undefined;
  menuItems: MenuItem[] | [];
  dynamicBreadcrumbValues: [];
  fileAreaSettings: FileAreaSettings;
  apiError: string | undefined;
  menuApiError: string | undefined;
  authUrl: string | undefined;
  tenantContext: any | null;
}

const defaultSettings: FileAreaSettings = {
  urlFileUpload: false,
  internalFileStatusUpdate: false,
  internalFilesEmail: false,
  internalFileSetAssignee: false,
  internalFileSetProject: false,
  internalFilesDownload: false,
  internalFilesCheckinCheckout: false,
  internalFilesRecategorize: false,
  fileAreaFolderStructure: false,
  fileAreaManageEmailSubscription: false,
  fileAreaManageTags: false,
  internalFilesAssign: false,
  internalFilesCopy: false,
  internalFilesDelete: false,
  internalFilesHistory: false,
  internalFilesMove: false,
  internalFilesRename: false,
  internalFilesUpdate: false,
  internalFilesUpload: false,
  internalFilesView: false,
  internalFilesViewAssignmentHistory: false,
  internalFilesViewCheckoutHistory: false,
  internalFilesViewProperties: false,
  internalFilesViewVersionHistory: false,
  portalFilesView: false,
  internalFilesPublishUnPublish: false,
};

export const initialState: IAppState = {
  endPoints: null,
  tenant: localStorage.getItem(constants.tenatKey),
  user: null,
  menuItems: [],
  selectedSite: '',
  publishedFileCount: undefined,
  dynamicBreadcrumbValues: [],
  fileAreaSettings: defaultSettings,
  apiError: undefined,
  menuApiError: undefined,
  authUrl: undefined,
  tenantContext: null
};

export type IAppInitializing = IAppState & { isInitializing: boolean };

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTenant: (state: IAppState, action: PayloadAction<string | null>) => {
      if (action.payload) localStorage.setItem(constants.tenatKey, action.payload);
      else localStorage.removeItem(constants.tenatKey);
      state.endPoints = null;
      state.tenant = action.payload;
    },
    setEndpoints: (state: IAppState, action: PayloadAction<WEBEndpointType[]>) => {
      state.endPoints = action.payload;
    },
    setUser: (state: IAppState, action: PayloadAction<UserClaims<CustomUserClaims>>) => {
      state.user = action.payload;
    },
    setSelectedSite: (state: IAppState, action: PayloadAction<string>) => {
      state.selectedSite = action.payload;
    },
    setPublishedFileCount: (state: IAppState, action: PayloadAction<number | undefined>) => {
      state.publishedFileCount = action.payload;
    },
    setMenu: (state: IAppState, action: PayloadAction<MenuItem[]>) => {
      state.menuItems = action.payload;
    },
    setMenuApiError: (state: IAppState, action: PayloadAction<string | undefined>) => {
      state.menuApiError = action.payload;
    },
    setApiError: (state: IAppState, action: PayloadAction<string | undefined>) => {
      state.apiError = action.payload;
    },
    setTenantContext: (state: any, action: PayloadAction<string>) => {
      console.log('==',action.payload)
      state.tenantContext = action.payload;
    },
  },
});

export const { setEndpoints, setTenant, setUser, setMenu, setSelectedSite, setPublishedFileCount, setApiError, setMenuApiError, setTenantContext} = appSlice.actions;
export const selectAppState = (state: RootState): IAppState => state.app;
export const selectAppInitializing = (state: RootState): IAppInitializing => {
  return {
    ...state.app,
    isInitializing: !(state.app.tenant && state.app.tenantContext && state.app.endPoints && state.app.menuItems.length && state.app.selectedSite && !state.app.apiError),
  };
};

export default appSlice.reducer;
