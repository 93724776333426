import moment from 'moment';

export const getParameterizedUrl = (url: string, parameter: string) => {
  return url.replace('{id}', parameter);
};

export const getParameterizedUrlWithSubPath = (url: string, subPath: string) => {
  return `${url}/${subPath}`;
};

export const getParameterizedUrlWith = (url: string, parameters: { name: string; value: any }[]) => {
  let parameterizedUrl = url;
  parameters.forEach((parameter) => {
    parameterizedUrl = parameterizedUrl.replace(`{${parameter.name}}`, parameter.value);
  });
  return parameterizedUrl;
};

export const getMultipleParameterizedUrl = (url: string, parameterList: Array<any>) => {
  parameterList.forEach((parameter: any) => {
    url = url.replace(`{${parameter.key}}`, parameter.value);
  });
  return url;
};

export const getMultipleQueryUrl = (url: string, queryList: Array<any>) => {
  queryList.forEach((query: any, index) => {
    if (index === 0) {
      url = `${url}?${query.key}=${query.value}`;
    } else {
      url = `${url}&${query.key}=${query.value}`;
    }
  });
  return url;
};

export const toTitleCase = (sentence: string) => {
  let camelCaseRemovedString = '';
  for (const letter of sentence.split('')) {
    if (letter === letter.toUpperCase()) {
      camelCaseRemovedString = camelCaseRemovedString.concat(' ', letter);
    } else {
      camelCaseRemovedString = camelCaseRemovedString.concat(letter);
    }
  }
  return camelCaseRemovedString
    .split(' ')
    .map(function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export type formActionType = 'view' | 'save' | 'edit' | undefined;

export const FORBIDDEN_ERROR_CODE = 403;

/**
 * Convert short hand date string to utc adding zeros
 * @param shortFormatDate "format eg "2021-10-23"
 * @returns utc converted date
 */
export const getUtcByShortFormat = (shortFormatDate: string) => {
  let localDate = new Date(moment(shortFormatDate).format('YYYY-MM-DD HH:mm'));
  return moment.utc(localDate);
};

/**
 * Convert date and time to local date and time
 * @param utcDateTime
 * @returns local converted date and time
 */
export const getLocalDateTime = (utcDateTime: string) => {
  return utcDateTime ? moment(utcDateTime).local().format('YYYY-MM-DD HH:mm') : '';
};
