import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import OktaAuthClient from '@app/utils/oktaAuthClient/OktaAuthClient';
import changeUrl from '@app/api/changeUrl';

const baseQuery = (baseUrl: any) =>
  fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders(headers) {
      headers.set('Authorization', `Bearer ${OktaAuthClient()?.getAccessToken()}`);
      return headers;
    },
  });

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  const [baseUrl, path] = changeUrl(args);

  if (typeof args == 'string') args = path;
  else args.url = path;

  let result = await baseQuery(baseUrl)(args, api, extraOptions);

  return result;
};
