import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from './interceptorsSlice';
import { OperationalServiceTypes } from '@iris/discovery.fe.client';
import config from '@app/utils/config';


export type GetFirmLogoAndNameResponse = {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
}

export const apiSlice = createApi({
  reducerPath: '/firm',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    getFirmLogoAndName: builder.query<GetFirmLogoAndNameResponse, void>({
      query: () => {
        return `${config.api[OperationalServiceTypes.MasterDataService].themes}`;
      },
    }),
  }),
});

export const { useLazyGetFirmLogoAndNameQuery} = apiSlice;
