import { OktaAuth } from '@okta/okta-auth-js';
import config from '@app/utils/config';
import constants from '@app/utils/Constants';

let authUrl: any;

export const injectAuthUrl = (_store: any) => {
  authUrl = _store;
};

let instance: any;

const OktaAuthClient = () => {
  if (!instance) {
    instance = new OktaAuth({
      issuer: authUrl,
      clientId: config.clientId,
      redirectUri: constants.redirectUri,
      postLogoutRedirectUri: constants.logoutUrl,
      scopes: constants.scopes,
      tokenManager: {
        storageKey: constants.tokenKey,
      },
    });
  }
  return instance;
};

export default OktaAuthClient;
