import React from 'react';
import RouteConfigType from '../types/RouteConfigType';
const LoginPage = React.lazy(() => import('../pages/Login'));
const PublishedFiles = React.lazy(() => import('../pages/PublishedFiles'));
const SubmittedFiles = React.lazy(() => import('../pages/SubmittedFiles'));

const AppRoutes: RouteConfigType[] = [
  {
    path: '/',
    title: 'Login',
    component: LoginPage,
    exact: true,
    guard: [],
  },
  {
    path: '/publishedFiles',
    title: 'Published Files',
    component: PublishedFiles,
    exact: true,
    guard: [],
    useInBreadcrumbs: true,
  },
  {
    path: '/submittedFiles',
    title: 'Submitted Files',
    component: SubmittedFiles,
    exact: true,
    guard: [],
    useInBreadcrumbs: true,
  },
];

export default AppRoutes;
