import React from 'react';
import { Alert, Button, Layout, Skeleton } from 'antd';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import PageContent from '../../components/PageContent';
import SideMenuContainer from '../../components/SideMenu/SideMenuContainer';
import styles from './index.module.less';
import { useAppSelector } from '@app/hooks/useAppSelector';
import { selectAppInitializing, selectAppState } from '@app/appSlice';
import { errorNotification } from '@app/utils/antNotifications';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from '@app/components/Breadcrumbs';
import { LogoutOutlined } from '@ant-design/icons';
import { useOktaAuth } from "@okta/okta-react";

const MasterLayout = (props: any) => {
  const {apiError, menuApiError, isInitializing} = useAppSelector(selectAppInitializing);
  const {tenant, user, tenantContext} = useAppSelector(selectAppState);
  const {oktaAuth, authState} = useOktaAuth();

  if (apiError) {
    errorNotification([apiError], 'App Initialization Error');
  }
  const logout = () => {
    oktaAuth.signOut();
    localStorage.clear();
  };
  return (
    <Layout className={'yj_cp_main_layout'} style={{height: '100vh'}}>
      <Header {...props} />
      <Layout>
        <SideMenuContainer/>
        <Layout className={styles.yj_cp_masterlayout_wrapper}>
          <Breadcrumbs/>
          {isInitializing ? <Skeleton/> : <Outlet/>}
          {(isInitializing && menuApiError && user) && <div style={{padding: '25px'}}>
              <Alert
                  message={`We couldn\'t find an account for the username ${user.preferred_username}. Contact your organization\'s administrator for assistance `}
                  type="warning" action={
                <Button type="link" onClick={logout} icon={<LogoutOutlined style={{color: '#333333'}}/>}
                        style={{margin: '5px 15px'}}>
                  Logout
                </Button>
              }/>
          </div>}

        </Layout>
      </Layout>
    </Layout>
  );
};

export { PageTitle, PageContent };
export default MasterLayout;
