import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

type MasterLayoutPageContent = {
  children?: any;
};

const PageContent = (props: MasterLayoutPageContent) => {
  return (
    <Content
      style={{
        maxHeight: '87.5vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: '15px',
      }}
    >
      {props.children}
    </Content>
  );
};

export default PageContent;
