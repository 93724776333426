import App from '@app/App';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import DiscoveryFEClient from '@iris/discovery.fe.client';
import { store } from '@app/store/store';
import { BrowserRouter } from 'react-router-dom';
import config from "@app/utils/config";

export const DiscoveryClient = new DiscoveryFEClient(config.discoveryURL);

const PreLoad = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

createRoot(document.getElementById('root')!).render(<PreLoad />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
