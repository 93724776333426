import MenuConfigType from '@app/types/MenuConfigType';
import { AiOutlineFileDone, AiOutlineImport, AiOutlineInbox } from 'react-icons/ai';

const RequestsMenuConfig: MenuConfigType = {
  path: '/requests',
  title: 'Requests',
  key: 'Requests',
  icon: AiOutlineInbox,
  noRoute: true,
};

const SubmittedFilesMenuConfig: MenuConfigType = {
  path: '/submittedFiles',
  title: 'Submitted Files',
  key: 'SubmittedFiles',
  icon: AiOutlineImport,
  noRoute: false,
};

const PublishedFilesMenuConfig: MenuConfigType = {
  path: '/publishedFiles',
  title: 'Published Files',
  key: 'PublishedFiles',
  icon: AiOutlineFileDone,
  hasCount: true,
};

const AnnouncementsMenuConfig: MenuConfigType = {
  path: '/announcements',
  title: 'Announcements',
  key: 'Announcements',
  icon: AiOutlineFileDone,
  noRoute: true,
};

const DiscussionsMenuConfig: MenuConfigType = {
  path: '/discussions',
  title: 'Discussions',
  key: 'Discussions',
  icon: AiOutlineFileDone,
  noRoute: true,
};
/**
 * Dont remove unused Menu configs before go live
 */
const menuConfig: MenuConfigType[] = [ SubmittedFilesMenuConfig, PublishedFilesMenuConfig];

export default menuConfig;
