import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu, Select } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import { useOktaAuth } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/useAppSelector';
import { selectAppState, setTenant } from '@app/appSlice';
import {  useLazyGetFirmLogoAndNameQuery } from "@app/api/firmApiSlice";

const Header = (props: any) => {
  const [tIds, setTIds] = useState<Array<string>>([]);
  const [theme, setTheme] = useState<any>();
  const { tenant, user , tenantContext} = useAppSelector(selectAppState);
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [triggerGetFirmLogoAndName] = useLazyGetFirmLogoAndNameQuery();
  const tenantLogoUrl = `data:image/png;base64,${(tenantContext?.tenantIcon)}`


  useEffect(() => {
    if (authState?.isAuthenticated) {
      setTIds((authState?.accessToken?.claims.tid as Array<string>) ?? []);
      console.info(authState?.accessToken?.claims.tid )
      triggerGetFirmLogoAndName().then((res)=>{
        res?.data && setTheme(res.data)
      })
    }
  }, [authState?.isAuthenticated]);

  const onSelect = (val: string) => {
    dispatch(setTenant(val));
  };

  const logout = () => {
    oktaAuth.signOut();
    localStorage.clear();
  };
  const navStyle = {
    backgroundColor: `#${theme?.secondaryColor}` ,
    borderBottomColor:  `#${theme?.primaryColor}` ,
    borderBottomWidth: '5px',
  };
  return (
    <Layout.Header className={styles.yj_cp_header} style={navStyle}>
      <div onClick={() => navigate('/')} className={styles.yj_cp_firm_logo} style={{  ...(tenantContext?.tenantIcon ? { backgroundImage: `url(${tenantLogoUrl})`, backgroundSize: 'contain' } : {}) }}></div>
      <div onClick={() => navigate('/')} className={styles.yj_cp_firm_name}>x{tenantContext?.tenantDisplayName}x</div>
      <div style={{flexGrow:1 }}></div>
      {!props.hideLogout && (<>
          <div>{user?.name ?? ''}</div>
          <Button type="link"  onClick={logout} icon={<LogoutOutlined style={{ fontSize: '24px', color: '#FFFFFF' }} />}  style={{margin: '5px 15px' }}/>
            { tIds.length > 1 && (
                <div style={{ padding: '10px' }}>
                  Tenant :{' '}
                  <Select defaultValue={tenant} onChange={onSelect} style={{ pointerEvents: 'auto', width: '80px' }}>
                    {tIds.map((e) => (
                        <Select.Option value={e}>{e}</Select.Option>
                    ))}
                  </Select>
                </div>
            )}
      </>
      )}

    </Layout.Header>
  );
};
export default Header;
