import CommonLayout from '@app/layouts/CommonLayout';
import { LoginCallback } from '@okta/okta-react';
import { Skeleton } from 'antd';
import React from 'react';
import OktaError from '../../components/OktaError';

const LoginCallbackPage: React.FC = () => {
  const renderError = (props: any) => {
    const { error } = props;
    return (
      <CommonLayout>
        <OktaError message={error.errorSummary} showContactAdmin autoLogoutTime={10} />
      </CommonLayout>
    );
  };

  return <LoginCallback loadingElement={<Skeleton active />} errorComponent={renderError} />;
};

export default LoginCallbackPage;
