import React from 'react';
import styles from './index.module.less';
import { PageTitleProps } from './types';

const PageTitle = (props: PageTitleProps) => {
  return (
    <div className={props.pageTitleClassName ? props.pageTitleClassName : styles.yj_cp_page_title_wrapper}>
      <div className={styles.yj_cp_page_title}>
        <p>{props.title}</p>
      </div>
      <div className={styles.yjPageButtonGroupWrapper}>{props.children}</div>
    </div>
  );
};

export default PageTitle;
