import changeUrl from '@app/api/changeUrl';
import { upload } from '@app/api/uploadService';
import {
  DownloadFileRequest,
  DownloadFileResponse,
  DownloadPortalFileURIRequest,
  DownloadPortalFileURIResponse,
  DownloadPortalZipFileURIRequest,
  DownloadPortalZipFileURIResponse,
  DownloadZipFileRequest,
  DownloadZipFileResponse,
  GetSubmittedFilesRequest,
  GetSubmittedFilesResponse,
  RemoveFilesRequest,
  RemoveFilesResponse,
  SaveFileDataRequest,
  SaveFileDataResponse,
  UploadFilesRequest,
  UploadFilesResponse,
} from '@app/types/FileApiSliceTypes';
import { getParameterizedUrl } from '@app/utils';
import config from '@app/utils/config';
import formatDownloadFileName from '@app/utils/files/formatDownloadFileName';
import httpVerbs from '@app/utils/http/httpVerbs';
import { OperationalServiceTypes } from '@iris/discovery.fe.client';
import { createApi } from '@reduxjs/toolkit/query/react';
import download from 'downloadjs';
import { baseQueryWithReAuth } from './interceptorsSlice';

export const apiSlice = createApi({
  reducerPath: '/file',
  baseQuery: baseQueryWithReAuth,
  tagTypes: ['publishedFiles', 'submittedFiles'],
  endpoints: (builder) => ({
    getSubmittedFiles: builder.query<GetSubmittedFilesResponse, GetSubmittedFilesRequest>({
      query: ({ siteId, options }) => ({
        url: `${config.api[OperationalServiceTypes.PortalService].getSubmittedFiles}`,
        params: { siteId, ...options },
      }),
      providesTags: ['submittedFiles'],
    }),
    saveFileData: builder.mutation<SaveFileDataResponse, SaveFileDataRequest>({
      query: (data) => ({
        url: `${config.api[OperationalServiceTypes.PortalService].portalFilesUpload}`,
        method: httpVerbs.POST,
        body: data,
      }),
      invalidatesTags: ['submittedFiles'],
    }),
    downloadPortalFileURI: builder.query<DownloadPortalFileURIResponse, DownloadPortalFileURIRequest>({
      query: ({ fileId }) => ({
        url: getParameterizedUrl(`${config.api[OperationalServiceTypes.PortalService].downloadPortalFile}`, fileId),
      }),
    }),
    downloadPortalZipFileURI: builder.mutation<DownloadPortalZipFileURIResponse, DownloadPortalZipFileURIRequest>({
      query: ({ fileIds }) => ({
        url: `${config.api[OperationalServiceTypes.PortalService].downloadPortalZipFile}`,
        method: httpVerbs.POST,
        body: { fileIds },
      }),
    }),
    uploadFiles: builder.mutation<UploadFilesResponse, UploadFilesRequest>({
      queryFn: async ({ formData, onUploadProgress, cancelToken }, _api, _extraOptions, baseQuery) => {
        const [baseUrl, path] = changeUrl(config.api[OperationalServiceTypes.FileStorageService].uploadFiles);

        // return new Promise((resolve, reject) => {
        //       const xhr = new XMLHttpRequest();
        //       xhr.upload.addEventListener('progress', e => onUploadProgress(e));
        //       xhr.addEventListener('load', () => resolve({ data: {status: xhr.status, body: xhr.responseText }}));
        //       xhr.addEventListener('error', () => reject(new Error('File upload failed')));
        //       xhr.addEventListener('abort', () => reject(new Error('File upload aborted')));
        //       xhr.open('POST', `${baseUrl}${path}`, true);
        //       xhr.setRequestHeader("content-type", "multipart/form-data; boundary=-")
        //       xhr.setRequestHeader("Authorization",`Bearer ${OktaAuthClient()?.getAccessToken()}`)
        //       // Array.from(files).forEach((file, index) => formData.append(index.toString(), file));
        //       xhr.send(formData);
        //     });
        // return  new Promise((resolve, reject)=> {
        //   let ajax = new XMLHttpRequest();
        //
        //   // add progress event to find the progress of file upload
        //   ajax.upload.addEventListener("progress", (ev)=>{
        //     let totalSize = ev.total; // total size of the file in bytes
        //     let loadedSize = ev.loaded; // loaded size of the file in bytes
        //     console.log("Uploaded " + loadedSize + " bytes of " + totalSize + " bytes.");
        //     // calculate percentage
        //     let percent = (ev.loaded / ev.total) * 100;
        //   });
        //   // const [baseUrl, path] = changeUrl(config.api[OperationalServiceTypes.FileStorageService].uploadFiles);
        //
        //   ajax.open("POST", 'https://filestorage.dev.conarc.net/C8UT/api/files'); // replace with your file URL
        //   ajax.setRequestHeader('Authorization', 'Bearer eyJraWQiOiJZM25GMGludkZQcnZVaUFQRE5QUmRCdkVBQXBtLVVwUVJmWmdfUUpfcHpvIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULjEwZEFkSF9fSllkbjYtaV94bGVOSldMZU5lalR4ZXpnOWVlaVItMjlJY28iLCJpc3MiOiJodHRwczovL2lkZW50aXR5LWRldi5pcmlzLmNvLnVrL29hdXRoMi9kZWZhdWx0IiwiYXVkIjoiYXBpOi8vZGVmYXVsdCIsImlhdCI6MTY3NDgwOTAxOCwiZXhwIjoxNjc0ODEwODE4LCJjaWQiOiIwb2E0aTEyNzdpOHQwdlh4bTB4NyIsInVpZCI6IjAwdTRveHVkazVBYWFXTlJ4MHg3Iiwic2NwIjpbInByb2ZpbGUiLCJvcGVuaWQiLCJ0ZW5hbmN5Il0sImF1dGhfdGltZSI6MTY3NDc5MzUwOSwic3ViIjoiMDB1NG94dWRrNUFhYVdOUngweDciLCJ0aWQiOlsiQzhVVCIsIkM1VFkiLCJMOFVUIiwiTDVUWSJdfQ.bYJH4ZdesbyWrgBjzBZNvsDdf5RuDqxqwXU9zEjJgH6cOiMVVKMJRqZpn_k2Y78qza6ybl6FuFHaNOf4SQiakzLbOkX3koKVqZPSbSlBE6TFn9zGKLlLSBVa8nu56j1aJ9IGGP0DdODeP52q8PAEBLf-MBDfibbI5XugihS6L5-7mBQcs-SXHjy6DU0Vf4SO2MkP21yE2YrLXQNXIe6S6fiOt9StUBLCYzE8CoxRKn4bnZVimQWJmdRZmrCOMJIq1orh7J2kkUBiyos9A1vr8BQaD-K3IxlCaATIDqe581qi88XkY-7ectBBZbPTFNxC9AJh_sUR7RCt0CPDiDXO3Q' );
        //   ajax.onload = (e) =>{
        //     if (ajax.status == 200) {
        //       console.log('response', ajax.response, JSON.parse( ajax.response)); // JSON response
        //       resolve(JSON.parse(ajax.response));
        //     }
        //
        //   };
        //   // send request to the server
        //   ajax.send(formData);
        // })

        return upload({ formData, onUploadProgress, cancelToken });
      },
    }),
    removeFiles: builder.mutation<RemoveFilesResponse, RemoveFilesRequest>({
      query: (file) => ({
        url: `${config.api[OperationalServiceTypes.FileStorageService].uploadFiles}`,
        method: httpVerbs.DELETE,
        body: {
          siteId: file.siteId,
          referenceNumbers: file.referenceNumber,
        },
      }),
    }),
    downloadFile: builder.mutation<DownloadFileResponse, DownloadFileRequest>({
      queryFn: async ({ uri }, _api, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: OperationalServiceTypes.FileStorageService.toString() + uri,
          responseHandler: (response) => response.blob(),
        });

        const blob = new Blob([response.data as Blob]);
        download(blob, formatDownloadFileName((response.meta as any).response));
        return { data: null };
      },
    }),
    downloadZipFile: builder.mutation<DownloadZipFileResponse, DownloadZipFileRequest>({
      queryFn: async ({ uri, files }, _api, _extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: OperationalServiceTypes.FileStorageService.toString() + uri,
          responseHandler: (response) => response.blob(),
          method: httpVerbs.POST,
          body: { files },
        });

        const blob = new Blob([response.data as Blob]);
        download(blob, formatDownloadFileName((response.meta as any).response));
        return { data: null };
      },
    }),
  }),
});

export const {
  useGetSubmittedFilesQuery,
  useSaveFileDataMutation,
  useUploadFilesMutation,
  useRemoveFilesMutation,
  useDownloadPortalZipFileURIMutation,
  useLazyDownloadPortalFileURIQuery,
  useDownloadFileMutation,
  useDownloadZipFileMutation,
} = apiSlice;
