import axios from 'axios';
import HTTPRequestConfig from './interfaces/RequestConfig';
import httpVerbsExport from './httpVerbs';
import logger from '../logger';
import constants from '../Constants';
import { store } from '@app/store/store';

export const ERROR_TYPE_CANCEL = 'ERR_CANCELED';

const setHeaders = (customHeaders: any) => {
  const client = axios.create({ baseURL: constants.baseUrlPlaceholder });

  const access_token = JSON.parse(localStorage.getItem(constants.tokenKey) ?? '')?.accessToken;
  //common headers
  client.defaults.headers.common['Authorization'] = `Bearer ${access_token?.accessToken}`;
  // client.defaults.headers.common["Content-Type"] = "application/json";
  // client.defaults.headers.common["Cache-Control"] = "no-cache";
  // client.defaults.headers.common["Pragma"] = "no-cache";
  // client.defaults.headers.common["Expires"] = "0";

  //custom header override
  // eg :- {authorization:"abcd123",Content-Type:"something"}
  for (let header in customHeaders) {
    if (header === 'authorization') {
      client.defaults.headers.common['Authorization'] = `Bearer ${access_token?.accessToken}`;
    }
  }

  return client;
};

const changeUrl = (req: HTTPRequestConfig) => {
  let path: string;
  try {
    path = new URL(req.url ?? '').pathname.slice(1);
    req.url = path;
  } catch (error) {
    path = req.url ?? '';
  }
  const state = store.getState();

  const { endPoints, tenant } = state.app;

  let key = path.slice(0, path.indexOf('/api'));

  if (key) {
    let baseUrl = endPoints?.find((e) => e.serviceType.toString() == key);
    if (baseUrl) {
      req.baseURL = baseUrl.entrypointAddress + '/' + tenant;
      req.url = path.slice(path.indexOf('/'));
    }
  }
};

const axiosApiClient = async (options: HTTPRequestConfig, customHeaders?: any, serviceType?: String) => {
  const client = setHeaders(customHeaders ? customHeaders : {});

  changeUrl(options);

  const onSuccess = (data: any): any => {
    return data;
  };
  const onError = (error: any): any => {
    logger.error('YJ Client Portal', 'http', error);
    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      console.error('Error Message:', error.message);
    }
    throw error?.response?.data ? error.response.data : error;
  };

  try {
    const data = await client(options);
    return onSuccess(data);
  } catch (error) {
    return onError(error);
  }
};

export const httpVerbs = httpVerbsExport;
export default axiosApiClient;
