import CommonLayout from '@app/layouts/CommonLayout';
import React from 'react';
import TenantSelection from '@app/components/TenatSelection';

const TenantSelectionPage = () => {
  return (
    <CommonLayout>
      <TenantSelection />
    </CommonLayout>
  );
};

export default TenantSelectionPage;
