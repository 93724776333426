import { useOktaAuth } from '@okta/okta-react';
import { Button, Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout: React.FC = () => {
  let navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();

  useEffect(() => {
    if (authState?.isAuthenticated) navigate('/');
  }, [authState]);

  return (
    <Row justify="center" align="middle" style={{ minHeight: 'calc(100vh - 65px)' }}>
      <Col className="yj_cp_error_banner">
        <Row gutter={20} justify="center" align="middle">
          <Col span="24" style={{ textAlign: 'center', marginBottom: '20px' }}>
            You have been logged out !
          </Col>
          <Col>
            <Button className="yj_cp_error_btns" type="primary" onClick={() => oktaAuth.signInWithRedirect()}>
              Login
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Logout;
